import "./App.css";
import "./styles/components.css";
import Collapsible from "react-collapsible";

import "react-multi-carousel/lib/styles.css";
import { TbChevronDown, TbChevronUp } from "react-icons/tb";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

function App() {
  const navigate = useNavigate();

  return (
    <div
      className="max-w-[800px] px-12 mx-auto my-0 my-12 tablet:my-36"
      id="container"
    >
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="flex flex-row justify-between gap-4 items-center mb-16"
        >
          <div className="text-right w-full font-eb-garamond gap-2 flex flex-col">
            <h1 className="text-black-primary font-eb-garamond text-4xl">
              joe tey
            </h1>
            <p className="text-dark-gray font-eb-garamond">
              living in SF, but home is Australia
            </p>
            {/* <p className="text-dark-gray font-eb-garamond text-left">
              <span className="select-none">joetey at stanford dot edu</span>
            </p> */}
            <div className="flex flex-col gap-4 mt-8 mb-4">
              <p className="text-dark-gray font-eb-garamond text-lg text-left">
                I study CS @ Stanford. This summer, I'm working as an engineer
                on the (tiny) Data Infra team @{" "}
                <a className="link" href="https://watershed.com/">
                  Watershed
                </a>
                , fixing our complex emission pipelines. Our mission is to help
                companies decarbonize their emissions. I gravitate towards
                impactful projects, and aside from climate, care deeply about
                making high-quality education accessible.
              </p>
              <p className="text-dark-gray font-eb-garamond text-lg text-left">
                Personally, I really enjoy combining ambitious interfaces with
                cool, technical ideas like how we learn/think (
                <a className="link" href="https://numinous.productions/ttft">
                  tools for thought
                </a>
                ) and ML interpretability (can we build explainable systems?).
              </p>
              <p className="text-dark-gray font-eb-garamond text-lg text-left">
                On the side, I also do research at the{" "}
                <a className="link" href="https://piechlab.stanford.edu/">
                  Piech lab
                </a>{" "}
                (the coolest, applied research EdTech lab), while tinkering at
                my own{" "}
                <a
                  className="link"
                  href="#"
                  onClick={() => navigate("/the-lab")}
                >
                  lab
                </a>{" "}
                from time to time.
              </p>
            </div>
            <div className="text-right font-eb-garamond text-dark-gray mb-4">
              <ul>
                <li className="hover:before:content-['--->'] font-bold text-xl">
                  <span
                    className="cursor-pointer"
                    onClick={() => navigate("/the-lab")}
                  >
                    the lab
                  </span>
                </li>
                {/* <li className="hover:before:content-['--->']">
                  <span
                    className="tab_link cursor-pointer"
                    onClick={() => alert("coming soon")}
                  >
                    my thoughts
                  </span>
                </li> */}
                <li className="hover:before:content-['--->']">
                  <span
                    className="tab_link cursor-pointer"
                    onClick={() => navigate("/what-i-believe-in")}
                  >
                    what I believe in
                  </span>
                </li>
                <li className="hover:before:content-['--->']">
                  <span
                    className="tab_link cursor-pointer"
                    onClick={() => navigate("/my-part-of-the-internet")}
                  >
                    my part of the internet
                  </span>
                </li>
                <li className="hover:before:content-['--->']">
                  <span
                    className="tab_link cursor-pointer"
                    onClick={() => navigate("/books-im-reading")}
                  >
                    books I'm reading
                  </span>
                </li>
              </ul>
            </div>
            <div className="text-left font-eb-garamond text-dark-gray">
              <p>more random bits about me:</p>
              <ul className="list-disc pl-5">
                <li>
                  What if we{" "}
                  <a
                    href="https://www.edsurge.com/news/2023-10-05-as-ai-chatbots-rise-more-educators-look-to-oral-exams-with-high-tech-twist"
                    className="link"
                  >
                    scaled the oral exam
                  </a>
                  ? Last year, my best friend and I worked with 100+ K-12
                  schools around the world to scale formative, oral assessments
                  in the classroom. We built{" "}
                  <a href="https://sherpalabs.co/" className="link">
                    Sherpa
                  </a>
                  , a new medium for teachers to gauge true student
                  understanding through voice-enabled conversations.{" "}
                  <a href="/the-lab/sherpa" className="link">
                    Did it work?
                  </a>
                </li>
                <li>
                  With some{" "}
                  <a href="https://tj401.com/" className="link">
                    great
                  </a>{" "}
                  <a
                    href="https://stanford.edu/~cpiech/bio/index.html"
                    className="link"
                  >
                    people
                  </a>
                  , I helped build the first version of the IDE for{" "}
                  <a href="https://codeinplace.stanford.edu/" className="link">
                    Code in Place
                  </a>
                  . We teach CS to{" "}
                  <a
                    href="https://www.google.com/search?q=code+in+place+forbes&sourceid=chrome&ie=UTF-8"
                    className="link"
                  >
                    30k students
                  </a>{" "}
                  around the world, with 3k volunteer teachers (a human-centered
                  MOOC). Read about more thoughts{" "}
                  <a
                    href="/the-lab/code-in-place-platform-ide"
                    className="link"
                  >
                    here
                  </a>
                  !
                </li>
                <li>
                  I used to spend hours animating 2D shapes to be{" "}
                  <a
                    href="https://www.youtube.com/watch?v=4opPHzGhnb8"
                    className="link"
                  >
                    visually pleasing
                  </a>
                  . Influencers liked them, so I did{" "}
                  <a
                    href="https://www.youtube.com/@GeekyBracketsTutorials/videos"
                    className="link"
                  >
                    freelance design
                  </a>{" "}
                  for them. I got lazy. So, I built{" "}
                  <a
                    href="https://www.youtube.com/watch?v=kcATfCfHKsE"
                    className="link"
                  >
                    useful tools
                  </a>{" "}
                  to optimize my creative process. I then sold them to other
                  designers.
                </li>
                <li>
                  I enjoyed sharing what I learned, so I taught an online
                  audience of ~20k subscribers how to{" "}
                  <a
                    href="https://www.youtube.com/@GeekyBracketsTutorials/playlists"
                    className="link"
                  >
                    design animations
                  </a>
                  ,{" "}
                  <a
                    href="https://www.youtube.com/@GeekyBracketsTutorials/playlists"
                    className="link"
                  >
                    create websites
                  </a>
                  , and{" "}
                  <a
                    href="https://www.youtube.com/@GeekyBracketsTutorials/playlists"
                    className="link"
                  >
                    make games
                  </a>
                  .
                </li>
                <li>
                  In the past, I've built product for{" "}
                  <a href="https://kibo.school/" className="link">
                    Kibo
                  </a>
                  , an online STEM university for African students, and was a
                  full-stack engineer at{" "}
                  <a href="https://www.joinleland.com/" className="link">
                    Leland
                  </a>
                  ,{" "}
                  <a href="https://www.nab.com.au/" className="link">
                    NAB
                  </a>
                  , and{" "}
                  <a
                    href="https://www.brightlysoftware.com/en-au/products/assetic"
                    className="link"
                  >
                    Assetic
                  </a>
                  .
                </li>
              </ul>
            </div>
            {/* <div className="flex flex-row gap-2 justify-end">
              <a
                href="https://twitter.com/joetey_"
                target="_blank"
                rel="noreferrer"
              >
                <BsTwitter
                  size={18}
                  className="cursor-pointer text-light-gray hover:text-dark-gray"
                />
              </a>
              <a
                href="https://www.linkedin.com/in/joseph-tey/"
                target="_blank"
                rel="noreferrer"
              >
                <BsLinkedin
                  size={18}
                  className="cursor-pointer text-light-gray hover:text-dark-gray"
                />
              </a>
              <a
                href="https://github.com/josephtey"
                target="_blank"
                rel="noreferrer"
              >
                <BsGithub
                  size={18}
                  className="cursor-pointer text-light-gray hover:text-dark-gray"
                />
              </a>
            </div> */}
          </div>
        </motion.div>
      </AnimatePresence>
      <div className="mb-16">
        <p className="font-eb-garamond text-lg mb-9"></p>
      </div>
    </div>
  );
}

export default App;
